<template>
  <div class="option-range">
    <div class="type-chart">
      <div class="name-chart segment">
        <template v-for="(chart, i) in chartType">
          <div
            class="item-range"
            :class="{ active: chart.value == optionTypeSelected }"
            :key="chart.value"
            @click="
              handleSelecteTypeChart(chart.value, chart.interaction_tally)
            "
          >
            {{ chart.name }}
          </div>
          <div
            class="item-range line"
            v-if="i < chartType.length - 1"
            :key="'split-' + i"
          >
            |
          </div>
        </template>

        <template>
          <div
            v-for="(item, index) in labelChart"
            :key="index"
            class="lengend-column"
            v-show="item.text"
          >
            <i
              class="icon icon-checkbox"
              :style="item.visible ? `background-color: ${item.color}` : ''"
              :class="item.visible ? 'checked' : ''"
              @click="handleSelectLabel(item.id)"
            ></i>
            <div :style="`color: ${item.color}`" class="pl-2">
              {{ item.text }}
            </div>
          </div>
        </template>
      </div>
      <div class="name-chart">
        <template v-for="(option, i) in options">
          <div
            class="item-range"
            :class="{ active: option.step == optionSelected }"
            :key="option.step"
            @click="handleSelected(option.step)"
          >
            {{ option.second }}s
          </div>
          <div
            class="item-range line"
            v-if="i < options.length - 1"
            :key="'split-' + i"
          >
            |
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";
export default {
  props: {
    options: Array, //ex: [{step: 1, second: 15}, {step: 2, second: 30}, {step: 4, second: 60}, {step: 8, second: 120}]
    eventName: String, //ex: name of event register in event bus
    chartType: Array,
    eventChartType: String,
    labelChart: Object,
    videoDetail: Object,
    selectChartType: Object,
  },
  data() {
    return {
      optionSelected: 1,
      optionTypeSelected: this.$store.getters.getChartTypeValue,
      optionLabelLeft: 1,
      eventSelectLabel: "selectLabel",
      checkedLabelBtn1: true,
      checkedLabelBtn2: true,
      checkedLabelBtn3: true,
      typeChartBasic: {
        all: 0,
        left: 1,
        right: 2,
      },
      checkedLabelStack: true,
    };
  },
  methods: {
    handleSelected(step) {
      this.optionSelected = step;
      EventBus.$emit(this.eventName, step);
    },
    handleSelecteTypeChart(typeValue, interaction_tally) {
      if (typeValue !== this.optionTypeSelected) {
        this.optionTypeSelected = typeValue;
        this.$store.commit("updateChartTypeValue", this.optionTypeSelected);
        EventBus.$emit(this.eventChartType, {
          typeValue: typeValue,
          interaction_tally: interaction_tally,
        });
      }
    },
    handleSelectLabel(type) {
      EventBus.$emit(this.eventSelectLabel, type);
    },
  },
  computed: {
    textInteraction() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 &&
          Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.text
            : "",
        rank_3:
          this.videoDetail.interaction_btn_2 &&
          Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.text
            : "",
        rank_4:
          this.videoDetail.interaction_btn_3 &&
          Object.keys(this.videoDetail.interaction_btn_3).length
            ? this.videoDetail.interaction_btn_3.text
            : "",
      };
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/variable";
.option-range {
  .type-chart {
    display: flex;
    justify-content: space-between;
    .name-chart {
      align-items: center;
      z-index: 2;
      display: flex;
      color: $middle-black;
      line-height: 28px !important;
      @include font(s);
      .item-range {
        &.line {
            color: #EBEBEB;
        }
        line-height: 16px;
        cursor: pointer;
        margin-right: 8px;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #999;
        &.active {
          color: #333;
          font-weight: 700;
        }
      }
      .lengend-column {
        font-family: "Noto Sans JP";
        font-weight: 400;
        font-size: 12px;
        line-height: 27px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        .icon-checkbox {
          background-image: unset;
          background-color: #ebebeb;
          -webkit-mask: $icon-checkbox no-repeat center;
          mask: $icon-checkbox no-repeat;
          &.checked {
            -webkit-mask: $icon-checkbox-checked no-repeat center;
            mask: $icon-checkbox-checked no-repeat;
            background-color: #49ab94;
          }
        }
      }
      &.segment {
        line-height: 40px !important;
        .lengend-column-segment {
          cursor: pointer;
          padding: 6px 20px;
          border: 1px solid $middle-black;
          border-radius: 8px;
          @include font(m);
          display: flex;
          align-items: center;
          margin-left: 8px;
          font-weight: bold;
          &.active {
            color: $green;
            border-color: $green;
          }
        }
      }
    }
  }
  .icon-lengend {
    display: flex;
    align-items: center;
    height: 9px;
    justify-content: flex-end;
    cursor: pointer;
    @include font(xs);
    color: $middle-black;
    font-weight: bold;
    position: relative;
    top: 10px;
    .label {
      color: $green;
    }
    .item-select {
      height: 7px;
      width: 7px;
      border-radius: 100%;
      border: 2px solid $middle-black;
      &.active {
        border-color: $green;
      }
    }
    .label-opacity {
      opacity: 0.2;
    }
  }
}
</style>