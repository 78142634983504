<template>
  <div class="mt-5">
    <div class="title">最大値シーン</div>
    <div class="image-ranking">
      <div v-if="ranks" class="row-3 mt-4">
        <div class="row-3-col-1" v-for="(rank, index) in ranks" :key="index">
          <div v-if="rank && rank[0]" class="card card-max-interaction w-100">
            <div class="content">
              <div class="title mb-3">
                <div class="left">
                  <img
                    style="margin-right: 8px; width: 24px"
                    :src="interactionIcon[`rank_${index + 2}`]"
                    alt=""
                  />
                  {{ interactionText[`rank_${index + 2}`] }}の最大値シーン
                </div>
                <div class="right">{{ rank[0].interaction_count }}</div>
              </div>
              <div class="top">{{ rank[0].second }}s</div>
              <div class="body">
                <img class="card-image" :src="rank[0].image_url" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { numberWithCommas } from "@/commons/helpers";
import { LABEL_TYPE_REACTION } from "@/commons/constants";
export default {
  name: "SecondRanking",
  props: ["videoDetail", "videoImage"],

  data() {
    return {
      ranks: [],
      NUMBER_BUTTON: LABEL_TYPE_REACTION.length,
    };
  },
  watch: {
    videoImage: function () {
      this.setImageSecond();
    },
  },
  mounted() {
    this.setImageSecond();
  },
  methods: {
    setImageSecond() {
      if (this.$props.videoImage) {
        let url = this.$props.videoImage.image_url;
        let interaction_rank = this.$props.videoImage.interaction_rank;
        for (let i = 0; i < this.NUMBER_BUTTON; i++) {
          let rank = [];
          let listRank = interaction_rank[`${i + 2}_rank`];
          for (let item in listRank) {
            listRank[item].image_url = url + listRank[item].second + ".jpg";
            listRank[item].interaction_count = numberWithCommas(
              listRank[item].interaction_count
            );
            rank.push(listRank[item]);
          }
          this.ranks.push(rank);
        }
      }
    },
  },
  computed: {
    interactionIcon() {
      let interactionIcon = {};
      for (let i = 1; i <= this.NUMBER_BUTTON; i++) {
        if (
          this.videoDetail[`interaction_btn_${i}`] &&
          Object.keys(this.videoDetail[`interaction_btn_${i}`]).length
        )
          interactionIcon[`rank_${i + 1}`] =
            this.videoDetail[`interaction_btn_${i}`].btn_icon_url;
        else interactionIcon[`rank_${i + 1}`] = "";
      }
      return interactionIcon;
    },
    interactionText() {
      let interactionText = {};
      for (let i = 1; i <= this.NUMBER_BUTTON; i++) {
        if (
          this.videoDetail[`interaction_btn_${i}`] &&
          Object.keys(this.videoDetail[`interaction_btn_${i}`]).length
        )
          interactionText[`rank_${i + 1}`] =
            this.videoDetail[`interaction_btn_${i}`].text;
        else interactionText[`rank_${i + 1}`] = "";
      }
      return interactionText;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../../styles/_top_detail.scss";
</style>