<template>
  <div class="d-flex align-items-center justify-content-center flex-column w-64 interaction-content">
    <!-- <div class="d-flex mt-3">
      <div
        class="sentiment-posinega-positive"
        :class="{ invisible: !sentiment_posinega.positive }"
        :style="{ width: sentiment_posinega.positive + '%' }"
      >
        <p class="m-0" :class="{ invisible: sentiment_posinega.positive < 10 }">
          Positive
          {{
            sentiment_posinega.positive
              ? sentiment_posinega.positive.toFixed(1)
              : this.valueDefault
          }}%
        </p>
        <div class="positive-line cursor-pointer">
          <span
            v-if="sentiment_posinega.positive < 10"
            class="text-tooltip"
            :class="{ 'text-tooltip-right': sentiment_posinega.positive < 5 }"
            >Positive
            {{
              sentiment_posinega.positive
                ? sentiment_posinega.positive.toFixed(1)
                : this.valueDefault
            }}%
          </span>
        </div>
      </div>
      <div
        class="sentiment-posinega-neutral"
        :class="[
          { invisible: !sentiment_posinega.neutral },
          { 'mr-0': !sentiment_posinega.negative },
          { 'ml-0': !sentiment_posinega.positive },
        ]"
        :style="{ width: sentiment_posinega.neutral + '%' }"
      >
        <p
          class="m-0"
          :class="[
            { invisible: sentiment_posinega.neutral < 10 },
            { 'text-right': !sentiment_posinega.negative },
            {
              'pl-3':
                !sentiment_posinega.negative && !sentiment_posinega.positive,
            },
          ]"
        >
          Neutral
          {{
            sentiment_posinega.neutral
              ? sentiment_posinega.neutral.toFixed(1)
              : this.valueDefault
          }}%
        </p>
        <div
          class="neutral-line cursor-pointer"
          :class="[
            { 'border-right-radius': !sentiment_posinega.negative },
            { 'border-left-radius': !sentiment_posinega.positive },
          ]"
        >
          <span
            v-if="sentiment_posinega.neutral < 10"
            class="text-tooltip"
            :class="[
              { 'text-tooltip-left': sentiment_posinega.negative < 5 },
              { 'text-tooltip-right': sentiment_posinega.positive < 5 },
            ]"
          >
            Neutral
            {{
              sentiment_posinega.neutral
                ? sentiment_posinega.neutral.toFixed(1)
                : this.valueDefault
            }}%
          </span>
        </div>
      </div>
      <div
        class="sentiment-posinega-negative"
        :class="{ invisible: !sentiment_posinega.negative }"
        :style="{ width: sentiment_posinega.negative + '%' }"
      >
        <p class="m-0" :class="{ invisible: sentiment_posinega.negative < 10 }">
          Negative
          {{
            sentiment_posinega.negative
              ? sentiment_posinega.negative.toFixed(1)
              : this.valueDefault
          }}%
        </p>
        <div class="negative-line cursor-pointer">
          <span
            v-if="sentiment_posinega.negative < 10"
            class="text-tooltip"
            :class="{ 'text-tooltip-left': sentiment_posinega.negative < 5 }"
            >Negative
            {{
              sentiment_posinega.negative
                ? sentiment_posinega.negative.toFixed(1)
                : this.valueDefault
            }}%
          </span>
        </div>
      </div>
    </div> -->
    <div class="d-flex w-100 list-interaction-line">
      <div
        v-if="interactionButton1 && interactionButton1.value && interactionButton1.text && interactionButton1.color"
        class="interaction-button"
        :class="{ invisible: !interactionButton1.value }"
        :style="{ width: interactionButton1.value + '%' }"
      >
        <p class="m-0" :class="{ invisible: interactionButton1.value < 18 }" :style="{ color: interactionButton1.color }">
          {{ interactionButton1.text }}
          {{
            interactionButton1.value.toFixed(1)
          }}%
        </p>
        <div class="interaction-button-line cursor-pointer" :style="{ backgroundColor: interactionButton1.color }">
          <span
            v-if="interactionButton1.value < 18"
            class="text-tooltip"
            :class="{ 'text-tooltip-right': interactionButton1.value < 5 }"
            :style="{ color: interactionButton1.color }"
            >{{ interactionButton1.text }}
            {{
              interactionButton1.value.toFixed(1)
            }}%
          </span>
        </div>
      </div>
      <div
        v-if="interactionButton2 && interactionButton2.value && interactionButton2.text && interactionButton2.color"
        class="interaction-button"
        style="text-align: center;"
        :class="[
          { invisible: !interactionButton2.value },
          { 'mr-0': !interactionButton3.value },
          { 'ml-0': !interactionButton1.value },
        ]"
        :style="{ width: interactionButton2.value + '%' }"
      >
        <p
          class="m-0"
          :class="[
            { invisible: interactionButton2.value < 18 },
            { 'text-right': !interactionButton3.value },
            {
              'pl-3':
                !interactionButton3.value && !interactionButton1.value,
            },
          ]"
           :style="{ color: interactionButton2.color }"
        >
          {{ interactionButton2.text }}
          {{
            interactionButton2.value.toFixed(1)
          }}%
        </p>
        <div
          class="interaction-button-line cursor-pointer"
          :class="[
            { 'border-right-radius': !interactionButton3.value },
            { 'border-left-radius': !interactionButton1.value },
          ]"
          :style="{ backgroundColor: interactionButton2.color }"
        >
          <span
            v-if="interactionButton2.value < 18"
            class="text-tooltip"
            :class="[
              { 'text-tooltip-left': interactionButton3.value < 5 },
              { 'text-tooltip-right': interactionButton1.value < 5 },
            ]"
            :style="{ color: interactionButton2.color }"
          >
            {{ interactionButton2.text }}
            {{
                interactionButton2.value.toFixed(1)
            }}%
          </span>
        </div>
      </div>
      <div
        v-if="interactionButton3 && interactionButton3.value && interactionButton3.text && interactionButton3.color"
        class="interaction-button"
        style="text-align: right;"
        :class="{ invisible: !interactionButton3.value }"
        :style="{ width: interactionButton3.value + '%' }"
      >
        <p class="m-0" :class="{ invisible: interactionButton3.value < 18 }" :style="{ color: interactionButton3.color }" >
          {{ interactionButton3.text }}
          {{
              interactionButton3.value.toFixed(1)
          }}%
        </p>
        <div class="interaction-button-line cursor-pointer"
        :style="{ backgroundColor: interactionButton3.color }">
          <span
            v-if="interactionButton3.value < 18"
            class="text-tooltip"
            :class="{ 'text-tooltip-left': interactionButton3.value < 5 }"
            :style="{ color: interactionButton3.color }"
            >{{ interactionButton3.text }}
            {{
               interactionButton3.value.toFixed(1)
            }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["videoDetail", "reportVideo", "comments", 'secondInteractionTotalData'],
  data() {
    return {
      valueDefault: "0.0",
    };
  },
  computed: {
    // sentiment_posinega() {
    //   let sentiment_posinega_valid =
    //     this.comments &&
    //     Object.keys(this.comments).length &&
    //     Object.keys(this.comments.sentiment_posinega).length;
    //   return {
    //     positive: sentiment_posinega_valid
    //       ? this.comments.sentiment_posinega.positive
    //       : "",
    //     neutral: sentiment_posinega_valid
    //       ? this.comments.sentiment_posinega.neutral
    //       : "",
    //     negative: sentiment_posinega_valid
    //       ? this.comments.sentiment_posinega.negative
    //       : "",
    //   };
    // },
    totalInteraction() {
      return this.secondInteractionTotalData && Object.keys(this.secondInteractionTotalData).length ? this.secondInteractionTotalData.total_2 + this.secondInteractionTotalData.total_3 + this.secondInteractionTotalData.total_4 : this.reportVideo && Object.keys(this.reportVideo).length ? this.reportVideo.total_2 + this.reportVideo.total_3 +  this.reportVideo.total_4 : 0
    },
    interactionButton1() {
      let interactionButton1Valid =
        this.videoDetail &&
        this.videoDetail.interaction_btn_1 &&
        Object.keys(this.videoDetail.interaction_btn_1).length;
      return {
        text: interactionButton1Valid
          ? this.videoDetail.interaction_btn_1.text
          : "",
        color: interactionButton1Valid
          ? this.videoDetail.interaction_btn_1.color
          : "",
        value: this.totalInteraction && Object.keys(this.secondInteractionTotalData).length ? Math.round(this.secondInteractionTotalData.total_2 / this.totalInteraction * 100) : this.totalInteraction && Object.keys(this.reportVideo).length ? Math.round(this.reportVideo.total_2 / this.totalInteraction * 100) : 0
      };
    },
    interactionButton2() {
      let interactionButton2Valid =
        this.videoDetail &&
        this.videoDetail.interaction_btn_2 &&
        Object.keys(this.videoDetail.interaction_btn_2).length;
      return {
        text: interactionButton2Valid
          ? this.videoDetail.interaction_btn_2.text
          : "",
        color: interactionButton2Valid
          ? this.videoDetail.interaction_btn_2.color
          : "",
        value: this.totalInteraction && Object.keys(this.secondInteractionTotalData).length ? Math.round(this.secondInteractionTotalData.total_3 / this.totalInteraction * 100) : this.totalInteraction && Object.keys(this.reportVideo).length ? Math.round(this.reportVideo.total_3 / this.totalInteraction * 100) : 0
      };
    },
    interactionButton3() {
      let interactionButton3Valid =
        this.videoDetail &&
        this.videoDetail.interaction_btn_3 &&
        Object.keys(this.videoDetail.interaction_btn_3).length;
      return {
        text: interactionButton3Valid
          ? this.videoDetail.interaction_btn_3.text
          : "",
        color: interactionButton3Valid
          ? this.videoDetail.interaction_btn_3.color
          : "",
        value: this.totalInteraction && Object.keys(this.secondInteractionTotalData).length ? Math.round(this.secondInteractionTotalData.total_4 / this.totalInteraction * 100) : this.totalInteraction && Object.keys(this.reportVideo).length ? Math.round(this.reportVideo.total_4 / this.totalInteraction * 100) : 0
      };
    },
  },
  methods: {
    colorInteraction(colorValue) {
      return colorValue == 1
        ? "#4976ab"
        : colorValue == 2
        ? "#e5bb26"
        : colorValue == 3
        ? "#ce6060"
        : colorValue == 4
        ? "#cf2f99"
        : colorValue == 5
        ? "#AB7249"
        : "";
    },
  }
};
</script>
<style lang="scss" scoped>
.invisible {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

p,
.text-tooltip {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.text-tooltip {
  position: absolute;
  z-index: 1;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
}

.text-tooltip-right {
  left: -2px;
  transform: translateX(0%);
}

.text-tooltip-left {
  left: 20px;
  transform: translateX(-100%);
}

.border-right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.border-left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.sentiment-posinega-positive {
  width: calc(100% / 3);
  color: #49ab94;

  .positive-line {
    position: relative;
    height: 12px;
    background-color: #49ab94;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.sentiment-posinega-neutral {
  width: calc(100% / 3);
  color: #999999;
  margin: 0 2px;

  .neutral-line {
    position: relative;
    height: 12px;
    background-color: #999999;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.sentiment-posinega-negative {
  width: calc(100% / 3);
  color: #ce6060;

  .negative-line {
    position: relative;
    height: 12px;
    background-color: #ce6060;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.list-interaction-line {
  .interaction-button {
    margin-left: 2px;

    .interaction-button-line {
      position: relative;
      height: 14px;

      &:hover .text-tooltip {
        visibility: visible;
      }
    }
  }

  .interaction-button:first-child {
    margin-left: 0;
    .interaction-button-line {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .interaction-button:last-child {
    .interaction-button-line {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.interaction-content {
  @media screen and (max-width: 1400px) {
    width: 100% !important;
    .list-interaction-line{
      margin-top: 0.75rem;
    }
  }
}


</style>