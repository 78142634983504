<template>
  <div class="mt-5 pb-5">
    <div class="title">コメントディスカバリーAI</div>
    <interaction-comment-chart :videoDetail="videoDetail" :videoImage="videoImage"
                               :reportVideo="reportVideo" :comments="comments" :scenes="scenes" @secondInteractionTotalData="getInteractionTotal">
    </interaction-comment-chart>
    <div v-if="!$store.getters.isLoadingApiVideoComment">
      <div class="d-flex justify-content-between mt-5 iframe-height">
        <div class="d-flex flex-column iframe-width">
          <div class="word-cloud-iframe" v-html="comments.word_cloud_html"></div>
          <div class="d-flex flex-wrap w-100 justify-content-between mt-5">
            <comment-detail :comments="comments"></comment-detail>
            <interaction-bar :videoDetail="videoDetail" :reportVideo="reportVideo" :comments="comments" :secondInteractionTotalData = "secondInteractionTotalData"></interaction-bar>
          </div>
        </div>
        <comment-list class="comment-list-width" :videoDetail="videoDetail" :comments="comments"
                      :commentNotFoundMsg="commentNotFoundMsg" :keyword="keyword" :attributeIds="attributeIds"
                      :attributeIdSelected="attributeIdSelected"
                      :attributeValueCommentCount="attributeValueCommentCount"
                      :isCsvCommentUploading="isCsvCommentUploading"
        >
        </comment-list>
      </div>
    </div>
    <div class="loader" v-else></div>
  </div>
</template>
<script>
import InteractionCommentChart from "@/components/TopDetail/InteractionCommentChart.vue";
import CommentDetail from "@/components/TopDetail/CommentDetail.vue";
import CommentList from "@/components/TopDetail/CommentList.vue";
import InteractionBar from "@/components/TopDetail/Step-1/InteractionBar.vue"; 

export default {
  props: [
    'videoDetail',
    'videoImage',
    'reportVideo',
    'comments',
    'keyword',
    'attributeIds',
    'attributeIdSelected',
    'scenes',
    'commentNotFoundMsg',
    'attributeValueCommentCount',
    'isCsvCommentUploading',
  ],
  components: {
    CommentList,
    CommentDetail,
    InteractionBar,
    InteractionCommentChart
  },
  data() {
    return {
      secondInteractionTotalData: {},
    }
  },
  methods: {
    getInteractionTotal(val){
      this.secondInteractionTotalData = val
    }
  }
}
</script>
<style lang="scss" scoped>
.iframe-height {
  height: 565px;
}

.iframe-width {
  width: calc(61% - 10px);

  .word-cloud-iframe {
    width: 100%;
    height: 467px !important;
  }
}

.comment-list-width {
  width: calc(39% - 10px);
}

.loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 20px auto auto;
}
</style>